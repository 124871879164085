<template>
   <v-app>
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="primary">
                        <v-toolbar-title>Makode Corretor</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <form ref="form">
                           <v-text-field v-model="username" name="username" label="Username" type="text"
                              placeholder="username" required></v-text-field>

                           <v-text-field v-model="password" name="password" label="Password" type="password"
                              placeholder="password" required></v-text-field>
                           <div class="red--text"> {{errorMessage}}</div>
                           <v-btn class="mt-4" color="primary" value="log in" @click="login()">Salvar</v-btn>
                        </form>
                     </v-card-text>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>


<script>
import axios from 'axios'


export default {
   name: "LoginPage",
   data() {
      return {
         username: "",
         password: "",
         errorMessage: "",

      };
   },
   methods: {
      login() {
         const { username } = this;
         console.log(username + "logged in")


         const usuario = { email: this.username, senha: this.password };

         axios.post("https://api-corretor.herokuapp.com/usuarios/login", usuario)
            .then(response => {
               //console.log(response.data)
               localStorage.setItem('TokenCorretora', response.data.token)
               console.log("Storage = " + localStorage.getItem('TokenCorretora'))
               //this.$router.push('/');
               const config = {
                  headers: {
                     "Authorization": "Bearer " + localStorage.getItem('TokenCorretora'),
                  }
               };
               axios.get("https://api-corretor.herokuapp.com/usuarios/perfil/" + username, config)
                  .then(response => {
                     console.log("perfil")
                     //console.log(response.data.imoveis[0].ie_comprador)
                     sessionStorage.setItem('comprador', response.data.imoveis[0].ie_comprador)
                     console.log("comprador = " + sessionStorage.getItem('comprador'))
                     sessionStorage.setItem('corretor', response.data.imoveis[0].ie_corretor)
                     console.log("corretor = " + sessionStorage.getItem('corretor'))
                     sessionStorage.setItem('vendedor', response.data.imoveis[0].ie_vendedor)
                     console.log("vendedor = " + sessionStorage.getItem('vendedor'))
                     this.$router.push('/');
                  }
                  );
            }
            )
            .catch(error => {
               this.errorMessage = error.message;
               this.errorMessage = "Login ou Senha inválido!!!"
            });



      },

   },

};
</script>

<style scoped>

</style>