<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" lg="3" md="4" sm="12" v-for="item in items" :key="item.ds_logradouro">
                <v-card>
                    <v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" height="200px"></v-img>
                    <v-card-title>
                        Top western road trips
                    </v-card-title>
                    <v-card-subtitle>
                        Logradouro: {{item.ds_logradouro}} <br />
                        Número: {{item.nr_numero}} <br />
                        Cidade: {{item.ds_logradouro}}
                    </v-card-subtitle>
                    <v-card-actions>
                        <v-btn color="orange lighten-2" text>
                            Detalhe
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-col>

            <v-btn fab color="primary" x-large :loading=this.loading @click="btnNovo" fixed class="botaoNovo">
                <v-icon x-large>mdi-plus</v-icon>
            </v-btn>
        </v-row>

    </v-container>

</template>

<style scoped>
.botaoNovo {
    right: 50px;
    bottom: 50px;
    padding: 0px;
}
</style>
  
<script>
import axios from 'axios'

export default {
    name: 'HomePage',
    data: () => ({
        loading: false,
        selection: 1,
        logradouro: 'Teste1',
        items: []
    }),
    mounted: function () {
        axios.get("https://api-corretor.herokuapp.com/imoveis",)
            .then(response => {
                console.log("axios get");
                this.logradouro = response.data.imoveis[0].ds_logradouro;
                this.items = response.data.imoveis;
            }
            );

    },
    methods: {
        reserve() {
            this.loading = true
        },
        btnNovo() {
            this.$router.push('/vendedor/cadastro/novo');

        }
    }
}


</script>
  