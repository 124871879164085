import Vue from 'vue'
import Router from 'vue-router'
import HomePage from '@/pages/HomePage'
import LoginPage from '@/pages/LoginPage'
import VendedorPage from '@/pages/VendedorPage'
import CadastroPage from '@/pages/CadastroPage'
import VendedorCadastro from '@/pages/VendedorCadastroPage'
import auth from '@/middleware/auth'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage,
      beforeEnter: (to, from, next) => {
        auth(next)
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginPage,
    },
    {
      path: '/cadastro',
      name: 'Cadastro',
      component: CadastroPage,
      beforeEnter: (to, from, next) => {
        auth(next)
      }
    },
    {
      path: '/vendedor',
      name: 'Vendedor',
      component: VendedorPage,
      beforeEnter: (to, from, next) => {
        auth(next)
      }
    },
    {
      path: '/vendedor/cadastro/:id',
      name: 'VendedorCadastro',
      component: VendedorCadastro,
      beforeEnter: (to, from, next) => {
        auth(next)
      }
    },

  ]
})