import Vue from 'vue'
import axios from 'axios';
import LoginPage from '@/pages/LoginPage'


Vue.use(auth);

export default async function auth(next) {
    var retorno = await verificaToken()
    if (retorno != true) {
        return next({
            path: '/login',
            name: 'Login',
            component: LoginPage,
        })
    }
    return next();
}

async function verificaToken() {
    var retorno = false;
    const config = {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('TokenCorretora'),
        }
    };
    await axios.get("https://api-corretor.herokuapp.com/usuarios/verificaToken", config)
        .then(response => {
            if (response.status == 200) {
                retorno = true
            }
        })
        .catch(err => console.log(err))
    return retorno;
}
