<template>
    <v-card>
        <v-toolbar flat>

            <template v-slot:extension>
                <v-tabs v-model="tabs" fixed-tabs>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab href="#mobile-tabs-5-1" class="primary--text">
                        <v-icon>mdi-phone</v-icon>
                    </v-tab>

                    <v-tab href="#mobile-tabs-5-2" class="primary--text">
                        <v-icon>mdi-heart</v-icon>
                    </v-tab>

                    <v-tab href="#mobile-tabs-5-3" class="primary--text">
                        <v-icon>mdi-account-box</v-icon>
                    </v-tab>
                </v-tabs>
            </template>
        </v-toolbar>

        <v-tabs-items v-model="tabs">
            <v-tab-item value="mobile-tabs-5-1">
                <v-card flat>
                    <v-card-text>
                        <v-container>

                            <v-form v-model="valid">
                                <v-container>
                                    <validation-observer ref="observer">
                                        <form @submit.prevent=" submit">
                                            <v-row>
                                                <v-col cols="12" md="10">
                                                    <validation-provider v-slot="{ errors }" name="Logradouro"
                                                        rules="required|max:10">
                                                        <v-text-field clearable outlined v-model="logradouro"
                                                            :counter="10" :error-messages="errors" label="Logradouro">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="2">
                                                    <validation-provider v-slot="{ errors }" name="número"
                                                        rules="required|max:10">
                                                        <v-text-field outlined clearable dense v-model="numero"
                                                            :counter="7" :error-messages="errors" label="Número"
                                                            type="number">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <validation-provider v-slot="{ errors }" name="cep"
                                                        rules="required">
                                                        <v-text-field outlined clearable dense v-model="cep"
                                                            :error-messages="errors" label="CEP">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="4">
                                                    <validation-provider v-slot="{ errors }" name="bairro"
                                                        rules="required">
                                                        <v-text-field outlined clearable dense v-model="bairro"
                                                            :error-messages="errors" label="Bairro">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="4">
                                                    <validation-provider v-slot="{ errors }" name="complemento"
                                                        rules="required">
                                                        <v-text-field outlined clearable dense v-model="complemento"
                                                            :error-messages="errors" label="Complemento">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <validation-provider v-slot="{ errors }" name="referencia"
                                                        rules="required">
                                                        <v-text-field outlined clearable dense v-model="referencia"
                                                            :error-messages="errors" label="Referência">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="4">
                                                    <validation-provider v-slot="{ errors }" name="estado"
                                                        rules="required">
                                                        <v-select outlined clearable dense v-model="estado"
                                                            :items="estados" :error-messages="errors" label="Estado"
                                                            data-vv-name="estado"></v-select>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="4">
                                                    <validation-provider v-slot="{ errors }" name="municipio"
                                                        rules="required">
                                                        <v-select outlined clearable dense v-model="municipio"
                                                            :items="municipios" :error-messages="errors"
                                                            label="Município" data-vv-name="tipo">
                                                        </v-select>
                                                    </validation-provider>
                                                </v-col>
                                            </v-row>

                                            <br />
                                            <v-row>
                                                <v-col cols="12" md="3">
                                                    <validation-provider v-slot="{ errors }" name="condominio"
                                                        rules="required">
                                                        <v-text-field outlined clearable dense v-model="condominio"
                                                            :counter="7" :error-messages="errors"
                                                            label="Valor do Condominio" type="number">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="3">
                                                    <validation-provider v-slot="{ errors }"
                                                        name="edificio ou condominio" rules="required">
                                                        <v-select outlined clearable dense v-model="edificio_condom"
                                                            :items="edificio_condoms" :error-messages="errors"
                                                            label="Edificio ou Condominio"
                                                            data-vv-name="edificio_condom"></v-select>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="2">
                                                    <validation-provider v-slot="{ errors }" name="aceita_permuta">
                                                        <v-checkbox outlined clearable dense v-model="aceita_permuta"
                                                            :error-messages="errors" value="1" label="Aceita Permuta"
                                                            type="checkbox">
                                                        </v-checkbox>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="2">
                                                    <validation-provider v-slot="{ errors }" name="tipo_permuta"
                                                        rules="required">
                                                        <v-select outlined clearable dense v-model="tipo_permuta"
                                                            :items="tipos_permuta" :error-messages="errors"
                                                            label="Tipo Permuta" data-vv-name="tipo">
                                                        </v-select>
                                                    </validation-provider>
                                                </v-col>

                                            </v-row>

                                            <v-row>


                                                <v-col cols="12" md="3">
                                                    <validation-provider v-slot="{ errors }" name="situacao_atual"
                                                        rules="required">
                                                        <v-select outlined clearable dense v-model="situacao_atual"
                                                            :items="situacoes_atual" :error-messages="errors"
                                                            label="Situacão Atual" data-vv-name="situacao_atual"
                                                            offset-y>
                                                        </v-select>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="3">
                                                    <validation-provider v-slot="{ errors }" name="valor" :rules="{
                                                      required: true,
                                                      digits: 7,
                                                    }">
                                                        <v-text-field outlined clearable dense v-model="valor"
                                                            :counter="7" :error-messages="errors" label="Valor"
                                                            type="number">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="3">
                                                    <validation-provider v-slot="{ errors }" name="tempo_construcao"
                                                        rules="required|max:10">
                                                        <v-text-field outlined clearable dense
                                                            v-model="tempo_construcao" :counter="10"
                                                            :error-messages="errors" label="Tempo Construção">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>



                                                <v-col cols="12" md="3">
                                                    <validation-provider v-slot="{ errors }" name="suites"
                                                        rules="required|max:2">
                                                        <v-text-field outlined clearable dense type="number"
                                                            v-model="suites" :counter="2" :error-messages="errors"
                                                            label="Suites">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>

                                            </v-row>

                                            <v-row>


                                                <v-col cols="12" md="2">
                                                    <validation-provider v-slot="{ errors }" name="quartos"
                                                        rules="required|max:2">
                                                        <v-text-field outlined clearable dense type="number"
                                                            v-model="quartos" :counter="2" :error-messages="errors"
                                                            label="Quartos">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="2">
                                                    <validation-provider v-slot="{ errors }" name="banheiros"
                                                        rules="required|max:2">
                                                        <v-text-field outlined clearable dense type="number"
                                                            v-model="banheiros" :counter="2" :error-messages="errors"
                                                            label="Banheiros">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="2">
                                                    <validation-provider v-slot="{ errors }" name="vagas"
                                                        rules="required|max:2">
                                                        <v-text-field outlined clearable dense type="number"
                                                            v-model="vagas" :counter="2" :error-messages="errors"
                                                            label="Vagas">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="2">
                                                    <validation-provider v-slot="{ errors }" name="área terreno"
                                                        rules="required|max:2">
                                                        <v-text-field outlined clearable dense type="number"
                                                            v-model="area_terreno" :counter="2" :error-messages="errors"
                                                            label="Área Terreno">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="2">
                                                    <validation-provider v-slot="{ errors }" name="área construida"
                                                        rules="required|max:2">
                                                        <v-text-field outlined clearable dense type="number"
                                                            v-model="area_construida" :counter="2"
                                                            :error-messages="errors" label="Área Construida">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="2">
                                                    <validation-provider v-slot="{ errors }" name="área privativa"
                                                        rules="required|max:2">
                                                        <v-text-field outlined clearable dense type="number"
                                                            v-model="area_privativa" :counter="2"
                                                            :error-messages="errors" label="Área Privativa">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col cols="12" md="2">
                                                    <validation-provider v-slot="{ errors }" name="isolamento">
                                                        <v-checkbox outlined clearable dense type="number"
                                                            v-model="isolamento" :counter="10" :error-messages="errors"
                                                            label="Isolamento">
                                                        </v-checkbox>
                                                    </validation-provider>
                                                </v-col>


                                                <v-col cols="12" md="2">
                                                    <validation-provider v-slot="{ errors }" name="piscina">
                                                        <v-checkbox outlined clearable dense type="number"
                                                            v-model="piscina" :counter="10" :error-messages="errors"
                                                            label="Piscina">
                                                        </v-checkbox>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="2">
                                                    <validation-provider v-slot="{ errors }" name="edicula">
                                                        <v-checkbox outlined clearable dense type="number"
                                                            v-model="edicula" :counter="10" :error-messages="errors"
                                                            label="Edicula">
                                                        </v-checkbox>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col cols="12" md="2">
                                                    <validation-provider v-slot="{ errors }" name="tem_inquilino">
                                                        <v-checkbox outlined clearable dense v-model="tem_inquilino"
                                                            :error-messages="errors" value="1" label="Tem inquilino"
                                                            type="checkbox">
                                                        </v-checkbox>
                                                    </validation-provider>
                                                </v-col>

                                            </v-row>
                                        </form>
                                    </validation-observer>
                                </v-container>
                            </v-form>

                            <v-btn class="mr-4" @click="submit()">
                                Salvar
                            </v-btn>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <v-tabs-items v-model="tabs">
            <v-tab-item value="mobile-tabs-5-2">
                <v-card flat>
                    <v-card-text>coração</v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <v-tabs-items v-model="tabs">
            <v-tab-item value="mobile-tabs-5-3">
                <v-card flat>
                    <v-card-text>contato</v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import axios from 'axios'


setInteractionMode('eager')

extend('digits', {
    ...digits,
    message: '{_field_} precisa ter {length} digitos. ({_value_})',
})

extend('required', {
    ...required,
    message: '{_field_} não pode ser vazio',
})

extend('max', {
    ...max,
    message: '{_field_} aceita no máximo {length} caracteres',
})

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
    ...email,
    message: 'Email must be valid',
})

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data: () => ({
        tabs: null,
        panel_endereco: [0, 1],
        readonly_endereco: false,

        panel_imovel: [0, 1],
        readonly_imovel: false,

        dialog: true,
        logradouro: '',
        numero: '',
        bairro: '',
        complemento: '',
        referencia: '',
        condominio: '',
        edificio_condom: null,
        estado: null,
        municipio: null,
        tipo_permuta: '',
        aceita_permuta: '',
        valor: '',
        tempo_construcao: '',
        situacao_atual: '',
        tem_inquilino: '',
        suites: '',
        quartos: '',
        banheiros: '',
        isolamento: '',
        mobiliado: '',
        piscina: '',
        edicula: '',
        area_privativa: '',
        area_construida: '',
        area_terreno: '',

        situacoes_atual: [
            'Casa',
            'Apartamento',
            'Kitnet',
            'Terreno',
        ],
        edificio_condoms: [
            'Casa',
            'Apartamento',
            'Kitnet',
            'Terreno',
        ],
        estados: [
            'Parana',
            'São Paulo',
            'Mato Grosso',
            'Paraiba',
        ],
        municipios: [
            'Maringá',
            'Cascavel',
            'Londrina',
            'Curitiba',
        ],
        tipos_permuta: [
            'Tipo_1',
            'Tipo_2',
            'Tipo_3',
            'Tipo_4',
        ],
    }),

    methods: {
        submit() {
            this.$refs.observer.validate().then(result => {
                console.log("validate all");
                if (!result) {
                    console.log("Tudo errado!");

                    return;
                } else {
                    console.log("Tudo certo!")
                    const bodyReq = {
                        logradouro: this.logradouro,
                        numero: this.numero,
                        bairro: this.bairro,
                        complemento: this.complemento,
                        referencia: this.referencia,
                        condominio: this.condominio,
                        edificio_condom: this.edificio_condom,
                        estado: this.estado,
                        municipio: this.municipio,
                        tipo_permuta: this.tipo_permuta,
                        aceita_permuta: this.aceita_permuta,
                        valor: this.valor,
                        tempo_construcao: this.tempo_construcao,
                        situacao_atual: this.situacao_atual,
                        tem_inquilino: this.tem_inquilino,
                        suites: this.suites,
                        quartos: this.quartos,
                        banheiros: this.banheiros,
                        isolamento: this.isolamento,
                        mobiliado: this.mobiliado,
                        piscina: this.piscina,
                        edicula: this.edicula,
                        sub_tipo: 1,
                        forma: "S",
                        cep: "cep",
                        latitude: 10,
                        longetude: 11,
                        vagas: 5,
                        area_terreno: this.area_terreno,
                        area_construida: this.area_construida,
                        area_privativa: this.area_privativa,
                    };
                    console.log("Bearer " + localStorage.getItem('TokenCorretora'));

                    const config = {
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem('TokenCorretora'),
                        }
                    };

                    axios.post("https://api-corretor.herokuapp.com/imoveis", bodyReq, config)
                        .then(response => {
                            console.log(response);
                        }
                        );
                }

                this.$router.push('/');
            });

        },

    },
}
</script>

<style>
.divider {
    /* minor cosmetics */
    display: table;
    font-size: 16px;
    text-align: center;
    width: 95%;
    /* divider width */
    margin: 10px auto;
    /* spacing above/below */
}

.divider span {
    display: table-cell;
    position: relative;
}

.divider span:first-child,
.divider span:last-child {
    width: 50%;
    top: 7px;
    /* adjust vertical align */
    -moz-background-size: 100% 2px;
    /* line width */
    background-size: 100% 2px;
    /* line width */
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
}

.divider span:first-child {
    /* color changes in here */
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(#000));
    background-image: -webkit-linear-gradient(180deg, transparent, #000);
    background-image: -moz-linear-gradient(180deg, transparent, #000);
    background-image: -o-linear-gradient(180deg, transparent, #000);
    background-image: linear-gradient(90deg, transparent, #000);
}

.divider span:nth-child(2) {
    color: #000;
    padding: 0px 5px;
    width: auto;
    white-space: nowrap;
}

.divider span:last-child {
    /* color changes in here */
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#000), to(transparent));
    background-image: -webkit-linear-gradient(180deg, #000, transparent);
    background-image: -moz-linear-gradient(180deg, #000, transparent);
    background-image: -o-linear-gradient(180deg, #000, transparent);
    background-image: linear-gradient(90deg, #000, transparent);
}
</style>